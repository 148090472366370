<script setup lang="ts">
const {
    forwardCallback,
    forwardLabel,
    layout,
    canProceed,
    isProceedDisabledVisually,
    isProceedPending,
    timeoutShowProceedPending,
    timeoutHideProceedPending,
} = useFooterState();

// After using forwarwd button, block for 1 second
// to prevent accidental double click triggering the next button (rage clicks)
// of the next page already
const throttledProceed = useThrottleFn(proceed, 1000);
function proceed() {
    // If proceed action takes > 1s, show loading spinner
    timeoutShowProceedPending.value = setTimeout(() => {
        isProceedPending.value = true;
    }, 1000);

    // If pending is not reset for any reason, reset it after 10s as safety net
    timeoutHideProceedPending.value = setTimeout(() => {
        isProceedPending.value = false;
    }, 10000);

    if (forwardCallback.value) {
        forwardCallback.value();
    }
}

const buttonLayout = computed(() => layout.value === 'floating' ? 'circle' : 'default');

const classes = computed(() => {
    const basicClasses = [
        'relative',
        'whitespace-nowrap',
        'overflow-hidden',
        'typo-button',
        'bg-brand-a',
        'text-brand-b',
        'disabled:bg-brand-a-2',
        'disabled:text-brand-a-3',
        'data-[disabled-visually=true]:bg-brand-a-2',
        'data-[disabled-visually=true]:text-brand-a-3',
        'hover:bg-shade-2',
        'transition-colors',
    ];

    const defaultClasses = [
        'px-[18px]',
        'py-3',
        'rounded-sm',
    ];

    const circleClasses = [
        'flex',
        'items-center',
        'justify-center',
        'rounded-full',
        'aspect-square',
        'p-4',
        'size-[64px]',
    ];

    switch (buttonLayout.value) {
        case 'circle':
            return [
                ...basicClasses,
                ...circleClasses,
            ];

        case 'default':
        default:
            return [
                ...basicClasses,
                ...defaultClasses,
            ];
    }
});
</script>

<template>
    <button
        v-if="!!forwardCallback"
        :class="classes"
        :disabled="!canProceed"
        :data-disabled-visually="isProceedDisabledVisually"
        @click="throttledProceed"
    >
        <span>{{ forwardLabel }}</span>
        <Transition name="fade">
            <div v-if="isProceedPending" class="absolute left-0 top-0 flex size-full items-center justify-center bg-brand-a-2 opacity-90">
                <LoadingSpinner class="!size-5" />
            </div>
        </Transition>
    </button>
</template>
